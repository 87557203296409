.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  padding: 10px 0;
  -webkit-overflow-scrolling: touch;
  background: none;

  /* Hide horizontal scrollbar (Chrome, Safari, Firefox) */
  scrollbar-width: none; 
}
.carousel::-webkit-scrollbar {
  display: none;
}

.carousel-item {
  /* Prevent shrinking, fix each item to a consistent width */
  flex: 0 0 auto;
  width: 280px; /* or any width you want */
  scroll-snap-align: start;
  margin: 0;
  border: none;
  background: none;
}
