.ai-chat-knowledge-page {
    /* Full screen gradient background */
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(168deg, black 0%, #67347F 100%);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* The large centered "card" occupying 60% x 80% */
  .ai-chat-card {
    width: 60%;
    height: 80%;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  