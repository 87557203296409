.custom-node {
  position: relative;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  font-family: Arial, sans-serif;
  font-size: 14px;
  
    .remove-node {
      position: absolute;
      top: -10px;
      right: -10px;
      background: #ff4d4d;
      color: white;
      border: none;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
  
      &:hover {
        background: #cc0000;
      }
    }
  
    .node-title,
    .node-text {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 0.5rem;
      margin-bottom: 1rem;
      font-size: 14px;
    }
  
    .quick-replies {
      display: flex;
      flex-direction: column; /* Stack quick replies vertically */
      gap: 0.5rem; /* Add space between quick replies */
  
      .quick-reply-container {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 36px; /* or 40px, adjust as you like */
        gap: 0.5rem;
  
        .quick-reply-input {
          background: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 0.5rem;
          flex-grow: 1;
          font-size: 14px;
        }
  
        .delete-quick-reply {
          margin-right: .5rem;

          background: #ccc;
          color: black;
          border: none;
          border-radius: 5px;
          padding: 0.5rem;
          cursor: pointer;
  
          &:hover {
            background: #bbb;
          }
        }
      }
    }
  
    .add-quick-reply {
      display: flex;
      gap: 0.5rem;
      margin-top: 1rem;
  
      input {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
      }
  
      button {
        padding: 0.5rem 1rem;
        background: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          background: #0056b3;
        }
      }
    }
  
    .react-flow__handle {
      width: 14px;
      height: 14px;
      background: #007bff;
      border: 2px solid #fff; /* a white border ring */
      border-radius: 50%;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
  