/********************************************
 * Outer Gradient + Centered Card
 ********************************************/
 .ai-chat-builder-page {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(168deg, #000, #67347f);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-chat-builder-card {
  width: 70%;       /* Smaller width than before (80%) */
  height: 65%;      /* Slightly smaller height as well */
  background: #fff;
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/********************************************
 * Left Chat Panel
 ********************************************/
.left-chat-panel {
  width: 60%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  padding: 16px;

  .chat-title {
    font-size: 18px;
    margin-bottom: 12px;
    color: #333;
  }

  .messages-container {
    flex: 1;
    background: #f9f9f9;
    padding: 12px;
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);

    .bubble {
      max-width: 70%;
      padding: 10px 14px;
      margin-bottom: 10px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 1.4;
      position: relative;
      clear: both;

      &.assistant {
        background-color: #e2e2e2;
        color: #333;
        float: left;
        border-bottom-left-radius: 0;
      }

      &.user {
        background-color: #0084ff;
        color: #fff;
        float: right;
        border-bottom-right-radius: 0;
      }

      &.typing-indicator {
        width: 50px;
        text-align: center;
        span {
          display: inline-block;
          width: 6px;
          height: 6px;
          margin: 0 2px;
          background: #333;
          border-radius: 50%;
          animation: typing 1.5s infinite;
        }
        span:nth-child(2) {
          animation-delay: 0.2s;
        }
        span:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }

    @keyframes typing {
      0% {
        transform: translateY(0);
        opacity: 0.3;
      }
      50% {
        transform: translateY(-6px);
        opacity: 1;
      }
      100% {
        transform: translateY(0);
        opacity: 0.3;
      }
    }
  }

  .input-area {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    
    .attach-btn {
      background-color: #eee;
      color: #333;
      padding: 0 12px;
      border-radius: 4px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      justify-content: center;
      &:hover {
        background-color: #ddd;
      }
    }
  
    .file-preview {
      width: 30px;   // reduced width
      height: 30px;  // reduced height
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  
    input[type="text"] {
      flex: 1;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;
      font-size: 14px;
      outline: none;
      &:disabled {
        background-color: #eee;
      }
    }
  
    button {
      background-color: #00a67e;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 0 16px;
      cursor: pointer;
      font-size: 14px;
      &:disabled {
        background-color: #aaa;
        cursor: not-allowed;
      }
    }
  }
  
}

/********************************************
 * Right Phone Panel
 ********************************************/
.right-phone-panel {
  width: 40%;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.message-image {
  max-width: 42%;
  height: auto;
  border-radius: 8px;
}

.file-attachment img {
  max-width: 40%;
  height: auto;
  border-radius: 4px;
}
