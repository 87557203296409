.message-node.normal {
  padding: 15px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 200px;
  position: relative;

  .remove-node {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #ff4d4d;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: #cc0000;
    }
  }

  .node-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input,
    textarea {
      font-size: 14px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    textarea {
      resize: none;
    }
  }
}
