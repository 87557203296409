.payment-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;

    /* NAVBAR */
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 2rem;
        background: #67347F;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
    }

    /* HERO SECTION */
    .hero-section {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        background-color: #f2f4f6;
        color: black;
        text-align: center;
        padding: 6rem 2rem;
        margin-bottom: 3rem;

        h1 {
            font-size: 3.2rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }

        p {
            font-size: 1.3rem;
            color: #333;
        }
    }

    /* PLANS SECTION */
    .plans-section {
        display: flex;
        justify-content: center;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .plan-container {
        width: 520px;
        border-radius: 12px;
        background: white;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        height: 750px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
        }
    }

    .plan-header {
        padding: 3rem;
        text-align: center;
        color: white;
    }

    .plan-price {
        font-size: 2.6rem;
        font-weight: bold;
        margin: 15px 0;
    }

    .select-plan-btn {
        background-color: white;
        color: black;
        padding: 16px;
        width: 100%;
        font-weight: bold;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transition: 0.3s;

        &:hover {
            background-color: #f1f1f1;
        }
    }

    .scaling-info {
        font-size: 1rem;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 10px;
        display: block;
    }

    .plan-body {
        padding: 2.5rem;
        background: white;
        flex-grow: 1;
    }

    .plan-features {
        list-style: none;
        padding: 0;
        margin: 2rem 0;

        li {
            margin: 1.2rem 0;
            font-size: 1.2rem;
            color: #333;
        }
    }

    /* PRICING SECTION */
    .pricing-section {
        margin-top: 5rem;
        padding: 4rem;
        background-color: #f8fafc;  /* Lighter, subtle gray */
        text-align: center;
        border-radius: 12px;
    
        h2 {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }
    
        p {
            font-size: 1.2rem;
            margin-bottom: 2rem;
            color: #555;
        }
    
        .pricing-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            flex-wrap: wrap;
        }
    
        .pricing-input {
            background: white;
            padding: 2rem;
            border-radius: 12px;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
            text-align: left;
            min-width: 320px;
    
            label {
                font-size: 1.2rem;
                font-weight: bold;
                display: block;
                margin-bottom: 0.5rem;
            }
    
            input[type="number"] {
                width: 100%;
                padding: 10px;
                font-size: 1.2rem;
                border: 1px solid #ccc;
                border-radius: 6px;
                margin-bottom: 1rem;
                text-align: center;
            }
    
            input[type="range"] {
                width: 100%;
                margin-top: 0.5rem;
                accent-color: #007bff;  /* Blue to match branding */
            }
        }
    
        .pricing-display {
            .pricing-card {
                background: #9cdeec;  /* Deep Blue, matches branding */
                color: white;
                padding: 2rem;
                border-radius: 12px;
                text-align: center;
                min-width: 250px;
    
                p {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
    
                button {
                    background: white;
                    color: #0056b3;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 1.2rem;
                    font-weight: bold;
                    transition: 0.3s;
    
                    &:hover {
                        background: #004099;
                        color: white;
                    }
                }
            }
        }
    
        /* Cost Styling */
        .estimated-cost {
            font-size: 1.4rem;
            font-weight: bold;
            color: #333;
            margin-top: 1.5rem;
        }
    }

    /* MOBILE FIXES */
    @media (max-width: 1024px) {
        .plans-section {
            flex-direction: column;
            align-items: center;
        }

        .plan-container {
            width: 90%;
            max-width: 500px;
            height: auto;
        }

        .hero-section {
            padding: 4rem 1.5rem;
        }
    }

    @media (max-width: 768px) {
        .pricing-modal {
            width: 90vw;
            max-width: 90vw;
        }

        .modal-title {
            font-size: 2rem;
        }

        .close-button {
            font-size: 1.6rem;
        }

        .modal-content {
            padding: 2rem;
        }

        .navbar {
            padding: 1rem;
        }

        .hero-section {
            padding: 3rem 1rem;
            margin-bottom: 2rem;
        }

        .plan-container {
            width: 100%;
            max-width: 420px;
            height: auto;
        }

        .plan-header {
            padding: 2.5rem;
        }

        .plan-price {
            font-size: 2rem;
        }

        .select-plan-btn {
            padding: 14px;
        }

        .plan-body {
            padding: 1.5rem;
        }

        .plan-features li {
            font-size: 1rem;
        }

        .pricing-section {
            padding: 2rem;
        }

        .pricing-container {
            flex-direction: column;
        }

        .pricing-input {
            width: 100%;
        }
    }
}

/* GLOBAL MODAL STYLES */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* The modal container itself */
.pricing-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 80%;
    max-width: 600px;
    border-radius: 12px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    max-height: 85vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    /* Add smooth appearance transitions */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Inner modal content wrapper (from old code) */
.modal-content {
    position: relative;
    width: 100%;
    max-width: 100%;
    /* If you want extra spacing inside, adjust here: */
    /* padding: 2rem; */
}

/* Title styling (from old code) */
.modal-title {
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #000;
    text-align: center;
}

/* Table styling (from old code) */
.pricing-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        text-align: center;
        padding: 14px;
        border-bottom: 1px solid #ddd;
    }

    th {
        background: #f8f8f8;
        font-weight: bold;
    }
}

/* Close Button */
.close-button {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    background: transparent;
    border: none;
    font-size: 1.8rem;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    transition: 0.3s ease-in-out;

    &:hover {
        color: #e74c3c;
    }
}
