.quick-replies {
  display: flex;
  gap: 8px;
  margin-top: 5px;

  button {
    border: 1px solid #000000 !important;
    background: white;
    color: #000000;
    padding: 8px 12px;
    border-radius: 16px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background: #007bff;
      color: white;
    }
  }
}
