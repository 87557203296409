.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #2c2c2c; /* Dark gray background */

  input {
    flex: 1;
    padding: 8px;
    border: 1px solid #444; /* Darker border */
    background: #1e1e1e;    /* Even darker input field */
    color: #fff;            /* White text */
    border-radius: 20px;
    outline: none;
    font-size: 13px;

    &::placeholder {
      color: #999;         /* Gray placeholder */
    }
  }

  button {
    margin-left: 8px;
    padding: 8px 12px;
    border: none;
    background: #0078ff;   /* Blue send button */
    color: white;
    border-radius: 20px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    transition: background 0.3s ease;

    &:hover {
      background: #005dc1; /* Darker blue on hover */
    }
  }
}
