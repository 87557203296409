.generic-template {
  /* Prevent shrinking and set a fixed size */
  flex: 0 0 auto;
  width: 280px;  /* or whatever width suits you */
  height: 320px; /* your fixed height */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 12px auto;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  /* Example content area if you have it */
  .content {
    flex: 1;
    padding: 12px 16px;
  }

  h3 {
    margin: 8px 0;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    color: #666;
    margin: 4px 16px 12px;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9);
  }

  button {
    width: 100%;
    background: transparent;
    border: none;
    color: #1877f2;
    font-weight: 600;
    font-size: 15px;
    padding: 14px 0;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
