.sidebar {
  padding: 1rem;
  background-color: #f4f4f4;
  border-right: 1px solid #ddd;
  height: 100vh;

  h3 {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.1rem;
  }

  .add-button,
  .save-button {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    color: white;
  }

  .add-button {
    background-color: #007bff;

    &:hover {
      background-color: #0056b3;
    }

    &.normal {
      background-color: #6c757d;

      &:hover {
        background-color: #495057;
      }
    }

    &.button {
      background-color: #28a745;

      &:hover {
        background-color: #218838;
      }
    }

    &.generic {
      background-color: #ffc107;

      &:hover {
        background-color: #e0a800;
      }
    }
    
    &.carousel {
      background-color: #9c27b0;

      &:hover {
        background-color: #7b1fa2;
      }
    }
  }

  .save-button {
    background-color: #17a2b8;

    &:hover {
      background-color: #138496;
    }
  }

  .template-input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
  }

  .template-dropdown {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
    background: #fff;
    color: #000;
  }
}
