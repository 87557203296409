.message-node.generic {
    padding: 15px;
    background-color: #fff3e0;
    border: 1px solid #ffe0b2;
    border-radius: 8px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .remove-node {
        position: absolute;
        top: -10px;
        right: -10px;
        background: #ff4d4d;
        color: white;
        border: none;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 12px;
        cursor: pointer;
      
        &:hover {
          background: #cc0000;
        }
      }
      
    input,
    textarea {
      font-size: 14px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      box-sizing: border-box;
    }
  
    img {
      max-width: 100%;
      margin: 10px 0;
      border-radius: 5px;
      object-fit: contain;
    }
  
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;
  
      .button-item {
        display: flex;
        align-items: center;
        gap: 8px;
  
        input {
          font-size: 14px;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 5px;
          flex: 1;
        }
  
        button {
          background-color: #ff9800;
          color: white;
          border: none;
          padding: 5px 8px;
          border-radius: 5px;
          font-size: 12px;
          cursor: pointer;
  
          &:hover {
            background-color: #e65100;
          }
        }
      }
    }
  
    .add-button-form {
      display: flex;
      flex-direction: column;
      gap: 8px;
  
      input {
        font-size: 14px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
      }
  
      button {
        background-color: #ff9800;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
  
        &:hover {
          background-color: #e65100;
        }
      }
    }
  }
  