.button-message {
    width: 280px; // Fixed width similar to the image
    height: 150px; // Fixed height for equal spacing
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .button-message-text {
    flex: 1; // Equal space for the message
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  
  .button-message-buttons {
    flex: 2; // The bottom 2 sections
    display: flex;
    flex-direction: column;
  }
  
  .button-message-buttons div {
    flex: 1; // Each button takes equal height
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff; // Full card width white background
    color: #007aff; // Blue text
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    width: 100%;
  }
  
  .button-message-buttons div:not(:last-child) {
    border-bottom: 1px solid #e0e0e0; // Light separator line
  }
  
  .button-message-buttons div:active {
    background-color: rgba(0, 122, 255, 0.1); // Click effect
  }
  