.client-demo {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #4b6cb7, #182848);
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .client-demo__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 600px;
  }
  
  .client-demo__title {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
  }
  
  .client-demo__greeting {
    font-size: 1.8rem;
    margin: 0;
  }
  
  .client-demo__client-description {
    font-size: 1.2rem;
    margin: 0;
    opacity: 0.9;
  }
  
  .client-demo__subtitle {
    font-size: 1.2rem;
    opacity: 0.8;
    margin: 10px 0;
  }
  
  
  
  .error-message {
    color: #ffcccc;
  }
  