.carousel-node {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 200px;
    position: relative;
    font-family: sans-serif;
  
    .remove-node {
      position: absolute;
      top: 2px;
      right: 2px;
      border: none;
      background: transparent;
      cursor: pointer;
      font-weight: bold;
      color: #888;
    }
  
    .node-content {
      margin-top: 20px;
      .db-label {
        font-size: 10px;
        color: #666;
        margin-bottom: 4px;
      }
      input[type="text"],
      input[type="number"] {
        width: 100%;
        margin-bottom: 4px;
        padding: 4px;
        font-size: 12px;
      }
      label {
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }
  