.ai-chat-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .left-panel {
    width: 40%;
    background: #fff;
    padding: 20px;
    box-shadow: inset -1px 0 0 #eaeaea;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    h2 {
      font-size: 20px;
      margin-bottom: 10px;
      color: #333;
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }

    .title-info-icon {
      font-size: 16px;
      color: #007bff;
      margin-left: 10px;
      cursor: pointer;
      position: relative;
    }
    .title-info-tooltip {
      display: none;
      position: absolute;
      top: 25px;
      left: 0;
      background: rgba(0, 0, 0, 0.75);
      color: #fff;
      padding: 5px 8px;
      border-radius: 4px;
      font-size: 12px;
      white-space: nowrap;
      z-index: 10;
    }
    .title-info-icon:hover + .title-info-tooltip {
      display: block;
    }

    p {
      font-size: 14px;
      color: #666;
      margin-bottom: 20px;
    }

    .input-section {
      margin-bottom: 20px;
      margin-right: 30px;

      label {
        font-size: 14px;
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
        color: #444;
      }

      .input-wrapper {
        position: relative;
      }

      input,
      textarea {
        width: 100%;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 6px;
        transition: border-color 0.3s ease;
      }

      input:focus,
      textarea:focus {
        border-color: #007bff;
        outline: none;
      }

      textarea {
        min-height: 50px;
        max-height: 200px;
        resize: none;
        overflow-y: hidden;
      }
    }

    .images-section {
      margin-bottom: 20px;

      h3 {
        margin-bottom: 10px;
      }

      .image-card {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 6px;
          border: 1px solid #ccc;
        }

        .no-image {
          width: 60px;
          height: 60px;
          background: #f1f1f1;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #999;
          font-size: 12px;
          border: 1px solid #ccc;
        }

        input[type="file"] {
          flex: 1;
        }

        input[type="text"] {
          width: 120px;
          border: 1px solid #ddd;
          padding: 5px;
          border-radius: 4px;
          transition: border-color 0.3s ease;
        }

        input[type="text"]:focus {
          border-color: #007bff;
          outline: none;
        }
      }

      .add-image-btn {
        background: linear-gradient(135deg, #9c27b0, #6a1b9a);
        color: #fff;
        border: none;
        padding: 8px 12px;
        border-radius: 6px;
        cursor: pointer;
        font-weight: bold;
        transition: background 0.3s ease;
        margin-top: 10px;

        &:hover {
          background: linear-gradient(135deg, #6a1b9a, #4a148c);
        }
      }
    }

    .pdf-upload-section {
      margin-top: 20px;
      padding-top: 10px;
      border-top: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .pdf-upload-label {
        font-weight: bold;
        color: #444;
      }

      .pdf-buttons {
        display: flex;
        gap: 15px;
      }

      .upload-pdf-btn,
      .download-pdf-btn {
        background: linear-gradient(135deg, #e53935, #d32f2f);
        color: #fff;
        border: none;
        padding: 8px 12px;
        border-radius: 6px;
        cursor: pointer;
        font-weight: bold;
        transition: background 0.3s ease, transform 0.2s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .upload-pdf-btn:hover,
      .download-pdf-btn:hover {
        transform: translateY(-2px);
        background: linear-gradient(135deg, #d32f2f, #c62828);
      }
    }

    .generated-section {
      margin: 20px 0;
      h3 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #333;
      }
      .output-group {
        margin-bottom: 15px;
        label {
          font-size: 14px;
          font-weight: bold;
          display: block;
          margin-bottom: 5px;
          color: #444;
        }
        textarea {
          width: 100%;
          border: 1px solid #ddd;
          padding: 10px;
          border-radius: 6px;
          transition: border-color 0.3s ease;
          min-height: 50px;
          resize: vertical;
        }
        textarea:focus {
          border-color: #007bff;
          outline: none;
        }
      }
      .output-buttons {
        display: flex;
        gap: 15px;
      }
    }

    .generate-btn,
    .save-btn {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-weight: bold;
      transition: background 0.3s ease, transform 0.2s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
    }

    .generate-btn {
      background: linear-gradient(135deg, #007bff, #0056b3);
      color: #fff;
      &:hover {
        background: linear-gradient(135deg, #0056b3, #003f7f);
      }
    }

    .save-btn {
      background: linear-gradient(135deg, #007bff, #0056b3);
      color: #fff;
      &:hover {
        background: linear-gradient(135deg, #0056b3, #003f7f);
      }
    }
  }

  .right-panel {
    flex: 1;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .chatphone-container {
      width: 100%;
      max-width: 500px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0,0,0,0.1);
      padding: 20px;
    }
  }

  /* PDF Modal – centered overlay */
  .modal-overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9999 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .modal {
    background: #fff !important;
    padding: 20px 25px !important;
    border-radius: 10px !important;
    max-width: 350px !important;
    width: 90% !important;
    text-align: center !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
  max-height: 40vh;
     overflow-y: auto;
    h3 {
      margin-bottom: 15px;
      font-size: 18px;
      color: #333;
    }
    p {
      margin-bottom: 20px;
      font-size: 14px;
      color: #555;
      line-height: 1.5;
    }
    .modal-actions {
      display: flex;
      justify-content: center;
      gap: 15px;
      .choose-file-btn,
      .modal-close-btn {
        background: linear-gradient(135deg, #e53935, #d32f2f);
        color: #fff;
        border: none;
        padding: 8px 16px;
        border-radius: 6px;
        cursor: pointer;
        font-weight: bold;
        transition: background 0.3s ease, transform 0.2s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        &:hover {
          transform: translateY(-2px);
        }
      }
      .modal-close-btn {
        background: linear-gradient(135deg, #007bff, #0056b3);
        &:hover {
          background: linear-gradient(135deg, #0056b3, #003f7f);
        }
      }
    }
  }
}


.error-message {
  font-size: 12px;
  color: red;
  margin: 10px 0;
  font-weight: normal;
}

.success-message {
  font-size: 12px;
  color: green;
  margin: 10px 0;
}
