.flow-builder-page {
  display: flex;
  flex-direction: column;
  height: 100vh; /* fill entire viewport */
  position: relative; /* needed if phone preview is absolutely positioned */
}

/* The phone simulator container */
.phone-preview-container {
  position: absolute;
  top: 60px; /* just below the top bar */
  right: 20px;
  z-index: 1000;
  
  /* Start "hidden" with scale(0) if you want a scaling-in effect */
  transform-origin: top right;
  transform: scale(0);
  transition: transform 1s ease; /* up to 1s transition */

  /* Alternatively, for a slide-in from the top:
     transform: translateY(-20px);
     opacity: 0;
     transition: all 1s ease;
  */
}
.phone-preview-container.show {
  transform: scale(1);
  /* or for slide in:
     transform: translateY(0);
     opacity: 1;
  */
}

.flow-builder-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 1rem;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

/* Left side is empty or remove it entirely if you want */
.topbar-left {
  width: 50px; /* or 0 if not needed */
}

.topbar-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.6rem;
    margin: 0.5rem 0;
    background: #eee;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    /* Increase icon size slightly if you want */
    svg {
      font-size: 1.2rem;
    }

    &:hover {
      background: #ddd;
    }
  }

  .set-live-btn {
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover {
      background: #0056b3;
    }
  }
}

.flow-builder {
  display: flex;
  flex: 1;
  height: 100%;
}
