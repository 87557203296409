.chatbot-template-page {
  background: linear-gradient(168deg, #000, #67347f);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  &__container {
    width: 100%;
    max-width: 960px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
    padding: 2rem;
    text-align: center;
    color: #fff;

    h1 {
      font-size: 2.8rem;
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    .cta-button {
      padding: 0.8rem 1.8rem;
      background: #ffd700;
      color: #000;
      border: none;
      border-radius: 8px;
      font-weight: bold;
      font-size: 1.1rem;
      cursor: pointer;
      transition: background 0.3s;
      margin-bottom: 1.5rem;

      &:hover {
        background: #ffca2c;
      }
    }

    .template-filters {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      input,
      select {
        width: 250px;
        padding: 0.8rem 1rem;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
      }

      input {
        background: rgba(255, 255, 255, 0.8);
        color: #000;
      }

      select {
        background: #fff;
        color: #000;
      }
    }

    .templates-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      gap: 2rem;
      justify-items: center;
      align-items: stretch;
    
      .template-card {
        position: relative;
        width: 100%;
        max-width: 320px;
        border-radius: 12px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
        }
    
        .card-container {
          position: relative;
        }
    
        .card-image {
          display: block;
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
    
        .card-placeholder {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 200px;
          background: #ccc;
          font-size: 1.2rem;
          color: #666;
        }
    
        .card-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 1rem;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
          color: #fff;
        }
    
        .card-title {
          margin: 0;
          font-size: 1.5rem;
          font-weight: bold;
        }
    
        .card-category {
          margin-top: 0.5rem;
          font-size: 1rem;
          opacity: 0.8;
        }
      }
    
      .no-results {
        grid-column: 1 / -1;
        font-size: 1.2rem;
        color: #fff;
      }
    }
    
  }
}
