/********************************************
 * Outer Gradient + Centered Card
 ********************************************/
 .builder-options {
  background-image: linear-gradient(168deg, #000, #67347f);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  &__container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  /* HEADER */
  &__header {
    text-align: center;
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.2rem;
      max-width: 700px;
      margin: 0 auto;
      line-height: 1.4;
    }
  }

  /* BUILDER CARDS SECTION */
  &__cards {
    text-align: center;

    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    &__card-grid {
      /* Force the cards to appear in a single horizontal row */
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 2rem;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-x: auto;
      padding: 1rem 0;
      margin: 0 auto;
      white-space: nowrap;
    }
  }

  /* INDIVIDUAL CARDS */
  .builder-card {
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.3);
    padding: 2rem 1.5rem;
    margin: 0.5rem; // Added margin on all sides for spacing between cards
    width: 270px;
    flex: 0 0 auto;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-5px);
    }

    &__icon {
      font-size: 3rem;
      color: #ffd700;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.4rem;
      margin-bottom: 0.75rem;
    }

    p {
      font-size: 0.95rem;
      line-height: 1.4;
      color: #e2e2e2;
      margin-bottom: 1rem;
    }

    .card-category {
      font-size: 0.8rem;
      color: #ccc;
      margin-bottom: 1rem;
      font-style: italic;
    }

    &__button {
      display: inline-block;
      padding: 0.6rem 1.2rem;
      background: #ffd700;
      color: #000;
      text-decoration: none;
      border-radius: 4px;
      font-weight: bold;
      font-size: 0.9rem;
      transition: background 0.3s;

      &:hover {
        background: #ffca2c;
      }
    }
  }

  /* "Recommended" badge in top-left corner of the card */
  .recommended-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #ff2d2d;
    color: #fff;
    padding: 0.25rem 0.6rem;
    border-radius: 6px;
    font-size: 0.75rem;
    font-weight: 600;
    z-index: 1;
  }

  /* TESTIMONIALS SECTION */
  &__testimonials {
    text-align: center;
    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
    .testimonial {
      max-width: 800px;
      margin: 1rem auto;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(8px);
      padding: 1.5rem;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

      p {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        color: #f1f1f1;
      }
      span {
        font-size: 0.9rem;
        opacity: 0.8;
      }
    }
  }

  /* FOOTER SECTION */
  &__footer {
    text-align: center;
    font-size: 0.9rem;
    opacity: 0.8;
    margin-bottom: 1rem;
  }

  /* RESPONSIVE: Tweak for narrower screens */
  @media (max-width: 768px) {
    &__header {
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
      }
    }
    &__cards h2 {
      font-size: 1.8rem;
    }
    .builder-card {
      width: 240px;
    }
    &__testimonials h2 {
      font-size: 1.6rem;
    }
  }
}
