.chat-phone {
    width: 280px;
    height: 480px;
    background: #1e1e1e;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  
    .chat-header {
      display: flex;
      align-items: center;
      background: #2c2c2c;
      padding: 10px 12px;
      border-bottom: 1px solid #444;
  
      .chat-avatar {
        background: #0078ff;
        color: #fff;
        border-radius: 50%;
        padding: 6px 10px;
        margin-right: 8px;
        font-weight: bold;
        font-size: 13px;
      }
  
      p {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        margin: 0;
      }
    }
  
    .chat-body {
      flex-grow: 1;
      overflow-y: auto;
      padding: 8px 8px 16px;
      display: flex;
      flex-direction: column;
  
      .message {
        max-width: 75%;
        padding: 8px 10px;
        border-radius: 12px;
        margin-bottom: 6px;
        font-size: 13px;
        line-height: 1.3;
        word-wrap: break-word;
  
        .message-text {
          color: #fff;
          margin-bottom: 4px;
        }
  
        .message-caption {
          color: #fff;
          font-size: 13px;
          margin-bottom: 4px;
        }
  
        .message-image {
          max-width: 100%;
          max-height: 150px;
          border-radius: 8px;
        }
      }
  
      .user {
        align-self: flex-end;
        background: linear-gradient(135deg, #0078ff, #005dc1);
        color: #fff;
        border-bottom-right-radius: 0;
      }
  
      .assistant {
        align-self: flex-start;
        background: #333;
        color: #fff;
        border-bottom-left-radius: 0;
      }
    }
  
    .chat-footer {
      display: flex;
      align-items: center;
      background: #2c2c2c;
      padding: 8px;
      border-top: 1px solid #444;
      gap: 8px;
  
      input {
        flex: 1;
        padding: 8px 10px;
        border: none;
        background: #333;
        color: #fff;
        border-radius: 20px;
        font-size: 13px;
        outline: none;
  
        &::placeholder {
          color: #999;
        }
      }
  
      button {
        border: none;
        background: #0078ff;
        color: #fff;
        padding: 8px 12px;
        border-radius: 20px;
        cursor: pointer;
        font-size: 13px;
        font-weight: bold;
        transition: background 0.3s ease;
  
        &:hover {
          background: #005dc1;
        }
      }
    }
  }
  